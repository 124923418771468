import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getRolesAPI } from 'services/msl/role';

export default function roleState() {
  const [roles, setRoles] = useState([]);
  const fetchAPI = useFetch();

  const getRole = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getRolesAPI(token, '?page=1&limit=1000'), options);
    let roles = res.data.roles.filter((val) => {
      return val.name !== 'Customer';
    });

    setRoles(roles);
  }, []);

  return {
    roles,
    getRole,
  };
}

export const initialRoleState = {
  roles: [],
  getRole: () => alert('getRole: unhandled function'),
};
